(function (factory) {
  typeof define === 'function' && define.amd ? define('navbar', factory) :
  factory();
})((function () { 'use strict';

  const mobileBtn = document.getElementById("mobile-cta");
  const ThemeToggle = document.getElementById("ThemeToggle");
  const nav = document.querySelector("nav");
  const mobileBtnExit = document.getElementById("mobile-exit");
  const laptopImage = document.querySelector(".IntroSection__image img");
  mobileBtn.addEventListener("click", () => {
    nav.classList.add("c-navbar__menu-btn");
  });
  mobileBtnExit.addEventListener("click", () => {
    nav.classList.remove("c-navbar__menu-btn");
  });
  ThemeToggle.addEventListener("click", () => {
    let bodyHasLightTheme = document.querySelector("body").classList.toggle("light");
    console.log(bodyHasLightTheme);
    let x = document.querySelector(".theme-icon");
    console.log(x.classList);

    if (bodyHasLightTheme) {
      localStorage.setItem("theme", "light");
      x.classList.add("fa-moon");
      x.classList.remove("fa-sun");
      laptopImage.setAttribute("src", "./images/laptop-dark.svg");
    } else {
      localStorage.setItem("theme", "dark");
      x.classList.remove("fa-moon");
      x.classList.add("fa-sun");
      laptopImage.setAttribute("src", "./images/laptop.svg");
    }
  });
  window.addEventListener("load", () => {
    const theme = localStorage.getItem("theme");
    console.log(theme);

    if (theme == "light") {
      document.querySelector("body").classList.add("light");
      document.querySelector(".theme-icon").classList.remove("fa-sun");
      document.querySelector(".theme-icon").classList.add("fa-moon");
      document.querySelector(".IntroSection__image img").setAttribute("src", "./images/laptop-dark.svg");
    }
  });

}));
